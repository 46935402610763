import {USER_COMPANY_SUCCESS, USER_COMPANY_ERROR} from '../actions/user_company';

const initialState = {
    data: '',
    id_company: null,
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === USER_COMPANY_SUCCESS) {
        return Object.assign({}, state, {
            data: action.payload,
            error: null});
    } else if (action.type === USER_COMPANY_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}