import {USER_SUCCESS, USER_ERROR} from '../actions/users';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    all: [],
    error: null,
    current:[],
    searched:[]
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.USERS.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.app_users,
            });
        case ACTIONS.USERS.GET:
            return Object.assign({}, state, {
                current: action.payload.app_user
            });
        case ACTIONS.USERS.DELETE:
            return state;
        case ACTIONS.USERS.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.USERS.PUT:
            state.all = state.all.filter(app_user => app_user.id_user != action.payload.app_user.id_user);
            state.all.push(action.payload.app_user);
            return {...state};
        case ACTIONS.USERS.POST:
            state.all.push(action.payload);
            return {...state}

    }


    if (action.type === USER_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === USER_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}