import {MEASUREMENT_STEM_SUCCESS, MEASUREMENT_STEM_ERROR} from '../actions/measurement_stem';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    deduction_bark: {},
    map_all: []
};

export default function reducer(state = initialState, action) {
    if (action.type === MEASUREMENT_STEM_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === ACTIONS.MEASUREMENT_STEMS.GET_DEDUCTION_BARK) {
        return Object.assign({}, state, {
            deduction_bark: action.payload.data[0]
        });
    } else if (action.type === MEASUREMENT_STEM_ERROR) {
        alert(action.error)
        return Object.assign({}, state, {
            error: action.error
        });
    } else if (action.type === ACTIONS.MEASUREMENT_STEMS.MAP_STEMS) {
        state.map_all = action.payload;
        return {...state}
    }
    return state;
}