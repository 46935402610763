import {API_BASE_URL} from '../config';
import {ACTIONS} from './_actions_types';
import {axiosGet, axiosDelete, axiosPost, axiosPut} from "../axios";
import {displayErrorToast, displaySuccessToast} from "./toast";

export const LOT_SUCCESS = 'LOT_SUCCESS';
export const fetchSuccess = data => ({
    type: LOT_SUCCESS,
    data
});

export const LOT_ERROR = 'LOT_ERROR';
export const fetchError = error => ({
    type: LOT_ERROR,
    error
});

export const getLots = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/lots/${localStorage.getItem('company')}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.GET_ALL,
            payload: res.data.lots
        });
        return res.data.lots;
    });
};

export const getLot = (id) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/lot/${id}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.GET,
            payload: res.data.lot
        });
        return res.data.lot
    });
};

export const postLot = (lot) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/lot`, lot).then((res) => {
        dispatch({
            type: ACTIONS.LOT.POST,
            payload: res.data
        })
        displaySuccessToast('Lot créer avec succès');
        return res.data.lot
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
        displayErrorToast(`Erreur lors de la création du lot`);
    });
};

export const searchLots = (data) => (dispatch) => {
    dispatch({
        type: ACTIONS.LOT.SEARCH,
        payload: {data}
    })
};
export const deleteLot = (id) => (dispatch) => {
    axiosDelete(`${API_BASE_URL}/lot/` + id).then((res) => {
        dispatch({
            type: ACTIONS.LOT.DELETE,
            payload: {id: id, lot: res.data}
        })
        displaySuccessToast('Lot supprimé avec succès');
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
        displayErrorToast(`Erreur lors de la suppression du lot`);
    });
};


export const putLot = (lot, displayToast = true) => (dispatch) => {
    return axiosPut(`${API_BASE_URL}/lot/${lot.id_lot}`, lot).then((res) => {
        // dispatch({
        //     type:ACTIONS.LOT.PUT,
        //     payload:res.data.lot
        // });
        if (displayToast) displaySuccessToast('Lot modifié avec succès');
        return res.data.lot
    }, (error) => {
        console.log("error", error)
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        });
        if (displayToast) displayErrorToast(`Erreur lors de la modification du lot`);
    });
};

export const postMeasurementsLotFilters = (isStem, filters) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/lot/filters`, {
        isStem: isStem,
        filters: filters
    }).then((res) => {
        dispatch({
            type: ACTIONS.LOT.MEASUREMENTS_FILTER,
            payload: res.data.measurements
        })
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};

export const getLotForPrint = (id) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/print/lot/${id}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.GET,
            payload: res.data.lot
        });
        return res.data.lot
    });
};


//Share

export const getPublicLot = (id, token) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/lot/${id}/${token}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.GET,
            payload: res.data.lot
        });
        return res.data.lot
    });
};

export const getPublicLotForPrint = (id, token) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/print/lot/${id}/${token}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.GET,
            payload: res.data.lot
        });
        return res.data.lot
    });
};