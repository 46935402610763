import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
    measurements_filter: {},
    measurements: {},
    lot_measurements: {},
    data: '',
    error: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.LOT.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload,
                searched: [],   //Vidage de la recherche
            });
        case ACTIONS.LOT.POST:
            state.all.push(action.payload);
            return {...state};

        case ACTIONS.LOT.MEASUREMENTS_FILTER:
            return Object.assign({}, state, {
                measurements_filter: action.payload
            });

        case ACTIONS.LOT.MEASUREMENTS:
            return Object.assign({}, state, {
                measurements: action.payload
            });

        case ACTIONS.LOT.LOT_MEASUREMENTS:
            return Object.assign({}, state, {
                lot_measurements: action.payload
            });

        case ACTIONS.LOT.PUT:
            state.all = state.all.filter(lot => lot.id_lot != action.payload.lot.id_lot);
            state.all.push(action.payload.lot)
            return {...state};

        case ACTIONS.LOT.DELETE:
            state.all = state.all.filter(lot => lot.id_lot != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });

        case ACTIONS.LOT.GET:
            return Object.assign({}, state, {
                current: action.payload
            });

        case ACTIONS.LOT.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });

        case ACTIONS.ERROR:
            alert(action.payload.data);
            break;
        default:
            return state;
    }
}
