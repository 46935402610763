import {PRICE_TYPE_SUCCESS, PRICE_TYPE_ERROR} from '../actions/price_type';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PRICE_TYPE.GET_ALL:
            state.all = action.payload;
            return {...state};
    }

    if (action.type === PRICE_TYPE_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === PRICE_TYPE_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}