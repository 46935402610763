import {DEPARTMENT_SUCCESS, DEPARTMENT_ERROR} from '../actions/department';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all:{}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.DEPARTMENT.GET_ALL:
            state.all = action.payload;
            return {...state};
    }
    if (action.type === DEPARTMENT_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === DEPARTMENT_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}