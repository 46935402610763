import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PRODUCT_TYPE.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.product_types
            });
        case ACTIONS.PRODUCT_TYPE.POST:
            state.all.push(action.payload);
            return {...state};
        case ACTIONS.PRODUCT_TYPE.PUT:
            state.all = state.all.filter(product_type => product_type.id_product_type != action.payload.action.id_product_type);
            state.all.push(action.payload.product_type)
            return {...state};
        case ACTIONS.PRODUCT_TYPE.DELETE:
            state.all = state.all.filter(product_type => product_type.id_product_type != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });
        case ACTIONS.PRODUCT_TYPE.GET:
            return Object.assign({}, state, {
                current: action.payload
            });
        case ACTIONS.PRODUCT_TYPE.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}