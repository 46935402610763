import {FOREST_TYPE_SUCCESS, FOREST_TYPE_ERROR} from '../actions/forest_type';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.FOREST_TYPE.GET_ALL:
            state.all = action.payload;
            return {...state};
    }

    if (action.type === FOREST_TYPE_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === FOREST_TYPE_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}