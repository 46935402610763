import {ACTIONS} from '../actions/_actions_types';
import {MEASUREMENT_ERROR, MEASUREMENT_SUCCESS} from "../actions/measurement";

let initialState = {
    all: {},
    current: {},
    searched: {},
    last_number: 0,
    all_stems: {},
    all_piles: {},
    volume: 0
};

export default function reducer(state = initialState, action) {
    if (action.type === MEASUREMENT_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === MEASUREMENT_ERROR) {
        alert(action.error)
        return Object.assign({}, state, {
            error: action.error
        });
    } else if (action.type === ACTIONS.MEASUREMENTS.GET_LAST_NUMBER) {
        return Object.assign({}, state, {
            last_number: action.payload
        });
    } else if (action.type === ACTIONS.MEASUREMENTS.GET_ALL_STEMS) {
        return Object.assign({}, state, {
            all_stems: action.payload,
            searched: [],
        });
    }  else if (action.type === ACTIONS.MEASUREMENTS.GET_ALL_PILES) {
        return Object.assign({}, state, {
            all_piles: action.payload,
            searched: [],
        });
    } else if (action.type === ACTIONS.MEASUREMENTS.GET_VOLUME) {
        return Object.assign({}, state, {
            volume: action.payload
        });
    }

    switch (action.type) {
        case ACTIONS.MEASUREMENTS.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.measurements,
                searched: [],
            });
        case ACTIONS.MEASUREMENTS.POST:
            state.all.push(action.payload);
            return {...state};
        case ACTIONS.MEASUREMENTS.PUT:
            state.all = state.all.filter(measurement => measurement.id_measurement != action.payload.measurement.id_measurement);
            state.all.push(action.payload.measurement)
            return {...state};
        case ACTIONS.MEASUREMENTS.DELETE:
            state.all = state.all.filter(measurement => measurement.id_measurement != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });
        case ACTIONS.MEASUREMENTS.GET:
            return Object.assign({}, state, {
                current: action.payload.measurement
            });
        case ACTIONS.MEASUREMENTS.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}
