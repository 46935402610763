import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';
import axios from "axios";
import {ACTIONS} from "./_actions_types";
import {axiosGet} from "../axios";

export const FOREST_TYPE_SUCCESS = 'FOREST_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: FOREST_TYPE_SUCCESS,
    data
});

export const FOREST_TYPE_ERROR = 'FOREST_TYPE_ERROR';
export const fetchError = error => ({
    type: FOREST_TYPE_ERROR,
    error
});

export const postForestType = (forest_type) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/forest_types`, forest_type)
        .then(({forest_type}) => dispatch(fetchSuccess(forest_type)));
};

export const getForestTypes = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/forest_types/`).then((res)=>{
        dispatch({
            type:ACTIONS.FOREST_TYPE.GET_ALL,
            payload:res.data.forest_types
        })
    });
};

export const getForestType = (id_forest_type) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/forest_types/${id_forest_type}`)
        .then(({forest_type}) => dispatch(fetchSuccess(forest_type)));
};

export const putForestType = (forest_type) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/forest_types/${forest_type.id_forest_type}`, forest_type)
        .then(({forest_type}) => dispatch(fetchSuccess(forest_type)));
};

export const deleteForestType = (id_forest_type) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/forest_types/${id_forest_type}`)
        .then(({forest_type}) => dispatch(fetchSuccess(forest_type)));
};