import {MEASUREMENT_PILE_SUCCESS, MEASUREMENT_PILE_ERROR} from '../actions/measurement_pile';
import {ACTIONS} from '../actions/_actions_types';

const initialState = {
    data: '',
    error: null,
    samples: [],
    assortment: null,
    map_all: []
};

export default function reducer(state = initialState, action) {
    if (action.type === MEASUREMENT_PILE_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === MEASUREMENT_PILE_ERROR) {
        alert(action.error)
        return Object.assign({}, state, {
            error: action.error
        });
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.GET_MEASUREMENT_PILE_SAMPLES) {
        return Object.assign({}, state, {
            samples: action.payload
        });
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.POST_MEASUREMENT_PILE_SAMPLES) {
        state.samples.push(action.payload);
        return {...state};
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.DELETE_MEASUREMENT_PILE_SAMPLES) {
        state.samples = state.samples.filter(measurement_pile_sample => measurement_pile_sample.id_measurement_pile_sample != action.payload.id);
        return Object.assign({}, state, {
            samples: state.samples
        });
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.PUT_MEASUREMENT_PILE_SAMPLES) {
        state.samples = state.samples.filter(measurement_pile_sample => measurement_pile_sample.id_measurement_pile_sample != action.payload.id);
        state.samples.push(action.payload)
        return {...state};
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.FIND_MEASUREMENT_ASSORTMENT) {
        state.assortment = action.payload
        return {...state}
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.MAP_PILES) {
        state.map_all = action.payload;
        return {...state}
    }
    return state;
}