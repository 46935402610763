import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
    cef: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.SALE_AND_PURCHASE.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.sales_and_purchases
            });
        case ACTIONS.SALE_AND_PURCHASE.POST:
            state.all.push(action.payload);
            return {...state};
        case ACTIONS.SALE_AND_PURCHASE.PUT:
            // console.log("payload", action.payload.sales_and_purchase)
            state.all = state.all.filter(sale_and_purchase => sale_and_purchase.id_sale_and_purchase != action.payload.sales_and_purchase.id_sale_and_purchase);
            state.all.push(action.payload.sales_and_purchase)
            return {...state};
        case ACTIONS.SALE_AND_PURCHASE.DELETE:
            state.all = state.all.filter(sale_and_purchase => sale_and_purchase.id_sale_and_purchase != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });
        case ACTIONS.SALE_AND_PURCHASE.GET:
            return Object.assign({}, state, {
                current: action.payload.sales_and_purchase
            });
        case ACTIONS.SALE_AND_PURCHASE.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.SALE_AND_PURCHASE.CEF:
            return Object.assign({}, state, {
                cef: action.payload
            });
        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}