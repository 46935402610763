import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';
import {fetchSuccess as fetchPriceTypeSuccess} from './price_type';
import axios from "axios";
import {ACTIONS} from "./_actions_types";
import {axiosGet} from "../axios";

export const DEPARTMENT_SUCCESS = 'DEPARTMENT_SUCCESS';
export const fetchSuccess = data => ({
    type: DEPARTMENT_SUCCESS,
    data
});

export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const fetchError = error => ({
    type: DEPARTMENT_ERROR,
    error
});

export const getDepartments = () => (dispatch, getState) => {
    const {token} = getState().auth;

    axiosGet(`${API_BASE_URL}/departments/`).then((res)=>{
        dispatch({
            type:ACTIONS.DEPARTMENT.GET_ALL,
            payload:res.data.departments
        })
    });
};


// PriceType
export const getPriceTypes = (id_department) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/departments/${id_department}/price_types`)
        .then((data) => {
            if (data?.price_types) {
                dispatch(fetchPriceTypeSuccess(data.price_types));
            }
        });
};
