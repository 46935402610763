import {INTERVENTION_TYPE_SUCCESS, INTERVENTION_TYPE_ERROR} from '../actions/intervention_type';

const initialState = {
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === INTERVENTION_TYPE_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === INTERVENTION_TYPE_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}