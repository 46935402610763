import {DIVISION_SUCCESS, DIVISION_ERROR} from '../actions/division';

const initialState = {
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === DIVISION_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === DIVISION_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}