// import {HAMMERING_SUCCESS, HAMMERING_ERROR} from '../actions/hammering';
import {ACTIONS} from '../actions/_actions_types';

let initialState = {
    all: {},
    current: {},
    searched: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.HAMMERING.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload,
                searched: [],
            });
        case ACTIONS.HAMMERING.POST:
            state.all.push(action.payload);
            return {...state};

        case ACTIONS.HAMMERING.PUT:
            state.all = state.all.filter(hammering => hammering.id_hammering != action.payload.hammering.id_hammering);
            state.all.push(action.payload.hammering)
            return {...state};

        case ACTIONS.HAMMERING.DELETE:
            state.all = state.all.filter(hammering => hammering.id_hammering != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });

        case ACTIONS.HAMMERING.GET:
            return Object.assign({}, state, {
                current: action.payload
            });

        case ACTIONS.HAMMERING.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });

        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}
