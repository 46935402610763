import {API_BASE_URL} from '../config';
import {get, put, del} from './utils';
import axios from "axios";
import {ACTIONS} from "./_actions_types";
import {axiosGet} from "../axios";

export const PRICE_TYPE_SUCCESS = 'PRICE_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: PRICE_TYPE_SUCCESS,
    data
});

export const PRICE_TYPE_ERROR = 'PRICE_TYPE_ERROR';
export const fetchError = error => ({
    type: PRICE_TYPE_ERROR,
    error
});

export const getPriceTypes = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/price_types/`).then((res)=>{
        dispatch({
            type:ACTIONS.PRICE_TYPE.GET_ALL,
            payload:res.data.price_types
        })
        return res.data.price_types.data
    });
};

export const getPriceType = (id_price_type) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/price_types/${id_price_type}`)
        .then(({price_type}) => dispatch(fetchSuccess(price_type)));
};

export const putPriceType = (price_type) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/price_types/${price_type.id_price_type}`, price_type)
        .then(({price_type}) => dispatch(fetchSuccess(price_type)));
};

export const deletePriceType = (id_price_type) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/price_types/${id_price_type}`)
        .then(({price_type}) => dispatch(fetchSuccess(price_type)));
};