import {ACTIONS} from '../actions/_actions_types';
import {COMPANY_ERROR, COMPANY_SUCCESS} from "../actions/company";

const initialState = {
    all: {},
    current: {},
    searched: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.COMPANIES.GET_ALL:
            state.all = action.payload;
            return {...state};

        case ACTIONS.COMPANIES.GET:
            return Object.assign({}, state, {
                current: action.payload,
            });

        case ACTIONS.COMPANIES.DELETE:
            state.all = state.all.filter(company => company.id_company != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });

        case ACTIONS.COMPANIES.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });

        case ACTIONS.COMPANIES.POST:
            state.all.push(action.payload);
            return {...state}

        case ACTIONS.COMPANIES.PUT:
            state.all = state.all.filter(company => company.id_company != action.payload.company.company.id_company);
            state.all.push(action.payload.company.company);
            return {...state};
        case COMPANY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                error: null
            });
        case COMPANY_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        default:
            return state;
    }


}