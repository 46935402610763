// import {CUT_SUCCESS, CUT_ERROR} from '../actions/cut';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all: [],
    searched: {},
    current: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.CUT.GET_ALL:
            state.all = action.payload;
            state.searched = [];
            return {...state}
        case ACTIONS.CUT.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.CUT.GET:
            return Object.assign({}, state, {
                current: action.payload
            });
        case ACTIONS.CUT.POST:
            state.all.push(action.payload);
            return {...state};

        case ACTIONS.CUT.PUT:
            state.all = state.all.filter(cut => cut.id_cut !== action.payload.cut.id_cut);
            state.all.push(action.payload.cut);
            return {...state};
    }
    // if (action.type === CUT_SUCCESS) {
    //     return Object.assign({}, state, {
    //         data: action.data,
    //         error: null
    //     });
    // } else if (action.type === CUT_ERROR) {
    //     return Object.assign({}, state, {
    //         error: action.error
    //     });
    // }
    return state;
}
