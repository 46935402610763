import {NEXT_ACTION, CUBAGE_FILL_LOT, SET_COMPANY} from '../actions/parameters';

const initialState = {
    id_company: null,
    id_cubage_fill_lot: null,
    next_action: null,
};

export default function reducer(state = initialState, action) {
    if (action.type === SET_COMPANY) {
        return Object.assign({}, state, {
            id_company: action.id_company
        });
    } else if (action.type === CUBAGE_FILL_LOT) {
        return Object.assign({}, state, {
            id_cubage_fill_lot: action.id_cubage_fill_lot
        });
    } else if (action.type === NEXT_ACTION) {
        return Object.assign({}, state, {
            next_action: action.next_action
        });
    }
    return state;
}