import {HAMMERING_STEM_SUCCESS, HAMMERING_STEM_ERROR} from '../actions/hammering_stem';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.HAMMERING_STEMS.GET_ALL:
            let newState = Object.assign({}, state, {
                all: action.payload
            });
            return newState;

        case ACTIONS.HAMMERING_STEMS.CLEAR:
            let clearedState = Object.assign({}, state, {
                all: {}
            });
            return clearedState;

    }
    if (action.type === HAMMERING_STEM_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === HAMMERING_STEM_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}