// import {HAMMERING_SUCCESS, HAMMERING_ERROR} from '../actions/hammering';
import {ACTIONS} from '../actions/_actions_types';

let initialState = {
    all: {},
    current: {},
    searched: {},
    certificates: {},
    divisions: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PARTNER.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload
            });

        case ACTIONS.PARTNER.POST:
            state.all.push(action.payload);
            return {...state};

        case ACTIONS.PARTNER.PUT:
            state.all = state.all.filter(partner => partner.id_partner != action.payload.partner.partner.id_partner);
            state.all.push(action.payload.partner.partner)
            return {...state};

        case ACTIONS.PARTNER.DELETE:
            state.all = state.all.filter(partner => partner.id_partner != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });

        case ACTIONS.PARTNER.GET:
            return Object.assign({}, state, {
                current: action.payload
            });

        case ACTIONS.PARTNER.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });

        case ACTIONS.PARTNER.GET_PARTNER_CERTIFICATES:
            return Object.assign({}, state, {
                certificates: action.payload.data.certificates
            });

        case ACTIONS.PARTNER.GET_PARTNER_DIVISIONS:
            return Object.assign({}, state, {
                divisions: action.payload.data.divisions
            });

        case ACTIONS.PARTNER.POST_PARTNER_CERTIFICATES:

            state.all.push(action.payload);
            return {...state};

        case ACTIONS.PARTNER.POST_PARTNER_DIVISIONS:
            // console.log("post payload", action.payload)

            state.all.push(action.payload);
            return {...state};

        case ACTIONS.PARTNER.PUT_PARTNER_CERTIFICATES:
            state.certificates = state.all.filter(certificate => certificate.id_certificate != action.payload.certificate.id_certificate);
            state.certificates.push(action.payload.certificate)
            return {...state};

        case ACTIONS.PARTNER.PUT_PARTNER_DIVISIONS:
            // console.log("reducer payload", action.payload)
            state.divisions = state.all.filter(division => division.id_division != action.payload.division.id_division);
            state.divisions.push(action.payload.division)
            return {...state};

        case ACTIONS.PARTNER.DELETE_PARTNER_CERTIFICATES:
            state.certificates = state.certificates.filter(certificate => certificate.id_certificate != action.payload.id);
            return Object.assign({}, state, {
                certificates: state.certificates
            });

        case ACTIONS.PARTNER.DELETE_PARTNER_DIVISIONS:
            state.divisions = state.divisions.filter(division => division.id_division != action.payload.id);
            return Object.assign({}, state, {
                divisions: state.divisions
            });

        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}
