import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.ACTION.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.actions
            });
        case ACTIONS.ACTION.POST:
            state.all.push(action.payload);
            return {...state};
        case ACTIONS.ACTION.PUT:
            state.all = state.all.filter(actAction => actAction.id_action != action.payload.action.id_action);
            state.all.push(action.payload.action)
            return {...state};
        case ACTIONS.ACTION.DELETE:
            state.all = state.all.filter(actAction => actAction.id_action != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });
        case ACTIONS.ACTION.GET:
            return Object.assign({}, state, {
                current: action.payload
            });
        case ACTIONS.ACTION.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}