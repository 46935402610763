import {API_BASE_URL} from '../config';
import {get, put, del} from './utils';
import {axiosGet, axiosPost} from "../axios";
import {ACTIONS} from "./_actions_types";

export const HAMMERING_STEM_SUCCESS = 'HAMMERING_STEM_SUCCESS';
export const fetchSuccess = data => ({
    type: HAMMERING_STEM_SUCCESS,
    data
});

export const HAMMERING_STEM_ERROR = 'HAMMERING_STEM_ERROR';
export const fetchError = error => ({
    type: HAMMERING_STEM_ERROR,
    error
});


export const postHammeringStems = (hammeringStem,id) =>  (dispatch) => {
    axiosPost(`${API_BASE_URL}/hammering_stems`, hammeringStem).then((res)=>{
        return axiosGet(`${API_BASE_URL}/hammering_stems/${id}`)
            .then((res) => {
                dispatch({
                    type:ACTIONS.HAMMERING_STEMS.GET_ALL,
                    payload:res.data
                })

            },(error)=> {
                dispatch({
                    type: ACTIONS.ERROR,
                    payload: error.response
                })
            })
    })
}

export const getHammeringStems = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/hammering_stems/`)
        .then(({hammering_stems}) => dispatch(fetchSuccess(hammering_stems)));
};

export const getHammeringStem = (id_hammering_stem) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/hammering_stems/${id_hammering_stem}`)
        .then((res) => {
            dispatch({
                type:ACTIONS.HAMMERING_STEMS.GET_ALL,
                payload:res
            })

        },(error)=> {
            dispatch({
                type: ACTIONS.ERROR,
                payload: error.response
            })
        })
};

export const putHammeringStem = (hammering_stem) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/hammering_stems/${hammering_stem.id_hammering_stem}`, hammering_stem)
        .then(({hammering_stem}) => dispatch(fetchSuccess(hammering_stem)));
};

export const deleteHammeringStem = (id_hammering_stem) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/hammering_stems/${id_hammering_stem}`)
        .then(({hammering_stem}) => dispatch(fetchSuccess(hammering_stem)));
};

export const clearHammeringStem = () => (dispatch) => {
    dispatch({
        type:ACTIONS.HAMMERING_STEMS.CLEAR,
    })
}