import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PRODUCT.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload.products,
                searched: [],
            });
        case ACTIONS.PRODUCT.POST:
            // state.all.push(action.payload);
            // return {...state};
            return state;
        case ACTIONS.PRODUCT.PUT:
            // state.all = state.all.filter(product => product.id_product != action.payload.product.id_product);
            // state.all.push(action.payload.product)
            // console.log("payload", action.payload.product)
            // return {...state};
            return state;
        case ACTIONS.PRODUCT.DELETE:
            state.all = state.all.filter(product => product.id_product != action.payload.id);
            return Object.assign({}, state, {
                all: state.all
            });
        case ACTIONS.PRODUCT.GET:
            return Object.assign({}, state, {
                current: action.payload
            });
        case ACTIONS.PRODUCT.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.ERROR:
            alert(action.payload.data);
        default:
            return state;
    }
}
