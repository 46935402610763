import {ACTIONS} from "../actions/_actions_types";
import {COUNTRY_SUCCESS, COUNTRY_ERROR} from "../actions/country";

const initialState = {
    data: '',
    error: null,
    all:{}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.COUNTRY.GET_ALL:
            state.all = action.payload;
            return {...state};
    }
    if (action.type === COUNTRY_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === COUNTRY_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}