import {ACTIONS} from '../actions/_actions_types';

const initialState = {
    licenses: [],
    addresses: [],
    currentAddresse: {},
    projects: [],
    project_meta: null,
    addresse_meta: null,
    currentProject: {},
    error: null
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.FORST_CONTROL.GET_ALL_LICENSES:
            state.licenses = action.payload;
            return {...state};

        case ACTIONS.FORST_CONTROL.GET_ALL_ADDRESSES:
            state.addresses = action.payload.addresses;
            state.addresse_meta = action.payload.meta;
            return {...state};

        case ACTIONS.FORST_CONTROL.GET_ADDRESSE:
            state.currentAddresse = action.payload;
            return {...state};

        case ACTIONS.FORST_CONTROL.GET_ALL_PROJECTS:
            state.projects = action.payload.projects;
            state.project_meta = action.payload.meta;
            return {...state};

        case ACTIONS.FORST_CONTROL.GET_PROJECT:
            state.currentProject = action.payload;
            return {...state};

        default:
            return state;
    }


}
