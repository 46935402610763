import {ROLE_SUCCESS, SET_ROLE_VALUE, ROLE_ERROR} from '../actions/role';
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    value: null,
    error: null,
    all:[]
};

export default function reducer(state = initialState, action) {
    switch(action.type)
    {
        case ACTIONS.ROLES.GET_ALL:
            return Object.assign({}, state, {
                all: action.payload,
            });
    }

    if (action.type === ROLE_SUCCESS) {
        return Object.assign({}, state, {
            data: action.data,
            error: null
        });
    } else if (action.type === SET_ROLE_VALUE) {
        return Object.assign({}, state, {
            value: action.value,
            error: null
        });
    } else if (action.type === ROLE_ERROR) {
        return Object.assign({}, state, {
            error: action.error
        });
    }
    return state;
}