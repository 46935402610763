/* eslint-disable default-case */
import actions from "redux-form/lib/actions";
import {ACTIONS} from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all: [],
    searched:{},
    current:{}
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case ACTIONS.TOKEN.GET_ALL:
            state.all = action.payload;
            state.searched = [];
            return {...state}

        case ACTIONS.TOKEN.SEARCH:
            return Object.assign({}, state, {
                searched: action.payload.data
            });
        case ACTIONS.TOKEN.GET:
            return Object.assign({}, state, {
                current: action.payload
            });
        case ACTIONS.TOKEN.REQUEST:
            state.all.push(actions.payload);
            return {...state};
        default:
            return state;
    }
}